.followbutton {
  margin: 5px;
  width:  90px;
}

.sidecardfollow {
  width: 6em !important;
  height: 30px;
  font-size: 11px !important;
  padding: 4px !important;
  border-radius: 40px !important;
  display: table-cell;
  vertical-align: middle;
  margin-right: 30px;
}
