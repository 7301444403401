@import url(https://fonts.googleapis.com/css?family=Roboto:300);

body {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* li {
    list-style-type:none;
} */

.post-inner {
  width: 100%;
}

.pop-playlist {
  position: fixed;
  bottom: 100px;
  right: 15px;
}

#playlist-card {
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

#nav-bar {
  display: flex;
  justify-content: space-between;
}

#bottomnav {
  display: flex;
  justify-content: space-between;
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #ff5c00;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4caf50;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}

.toFollowWrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.toFollow {
  top: 50%;
  position: absolute;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.optionContainer {
  background: #eeeeee;
  color: gray;
}

.optionContainer li.false:hover,
.optionContainer .highlight {
  background: #ff5c00;
  color: #fff;
}

.dropdown .dropdown-menu,
.btn-group .dropdown-menu {
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 32px -4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 32px -4px rgba(0, 0, 0, 0.12);
  padding: 15px 20px;
}

.dropdown .dropdown-menu .dropdown-item,
.btn-group .dropdown-menu .dropdown-item {
  margin-bottom: 10px;
  padding: 0;
  color: #131517;
  font: 300 15px "Open Sans", sans-serif;
  letter-spacing: 0;
  text-transform: none;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}

.dropdown .dropdown-menu .dropdown-item:last-child,
.btn-group .dropdown-menu .dropdown-item:last-child {
  margin: 0;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:active,
.btn-group .dropdown-menu .dropdown-item:hover,
.btn-group .dropdown-menu .dropdown-item:focus,
.btn-group .dropdown-menu .dropdown-item:active {
  background: transparent;
  color: #7c7c7c;
}

.modal.fade .modal-dialog {
  -webkit-transition: ease-out 0.2s;
  transition: ease-out 0.2s;
  -webkit-transform: translate(0) scale(0.98);
  transform: translate(0) scale(0.98);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0) scale(1);
  transform: translate(0) scale(1);
}

.modal .modal-content {
  -webkit-box-shadow: 0 4px 30px -4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 30px -4px rgba(0, 0, 0, 0.3);
  border: 0;
}
