.demoVideo:focus {
    outline: none;
}

.wrapper {
    display: block;
    width: 100%;
    height: 0;
    padding: 46% 0 0 0;
    position: relative;
}

.demoVideo {
    display: block;
    position: absolute;
    object-fit: fill;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.demoModal {
    height: 80vh;
}

.demoProgress {
    padding-bottom: 15px;
    text-align: center;
    font-family: Roboto;
    box-sizing: border-box;
}

.demoProgress .demoCircle,
.demoProgress .demoBar {
    display: inline-block;
    background: white;
    width: 40px;
    height: 40px;
    vertical-align: middle;
}

.demoProgress .demoCircle .label {
    border-radius: 50%;
    width: 37px;
    height: 37px;
    padding: 3px;
    background: white;
    border: 2px solid #C4C4C4;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #C4C4C4;
    display: inline-block;
}

.demoProgress .demoCircle.done .label {
    border-radius: 50%;
    width: 37px;
    height: 37px;
    padding: 1px;
    background: #FF8D4D;
    border: 2px solid #FF8D4D;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: white;
    display: inline-block;
}

.demoProgress .demoBar {
    background: #C4C4C4;
    border-radius: 0;
    width: 84px;
    height: 0px;
    margin: 0px 5px;
    border: 1px solid #C4C4C4;
}

.demoProgress .demoBar.done {
    background: #FF8D4D;
    border-radius: 0;
    width: 84px;
    height: 0px;
    margin: 0px 5px;
    border: 1px solid #FF8D4D;
}

.demoProgress .demoCircle .description {
    position: inline-block;
    text-align: center;
    width: 110px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    float: left;
    margin-top: 5px;
    margin-left: -30px;

    color: #9A9999;
}

.demoChromeExtButton {
    margin: 0 auto;
    width: fit-content;
    margin-top: 50px;
}

.demoLinkedinBox {
    margin: 0 auto;
    width: fit-content;
    vertical-align: middle;
    margin-top: 60px;
}

.demoIntroItem {
    display: flex;
    margin-left: 60px;
    margin-top: 3px;
}

.demoIntroItem .wander-logo {
    margin-left: 45px;
}

.demoIntroItem p {
    margin-left: 30px;
}

.demoIntroItem .demoIntroItem3 {
    margin-left: 20px;
}

.demoLinkedinFollowing {
    height: 50vh;
    overflow: auto;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="wrapper"] {
        padding: 85% 0 0 0;
    }

    .demoProgress {
        padding-bottom: 30px;
        padding-top: 10px;
        text-align: center;
        font-family: Roboto;
        box-sizing: border-box;
    }
    
    .demoProgress .demoCircle,
    .demoProgress .demoBar {
        display: inline-block;
        background: white;
        width: 26px;
        height: 26px;
        vertical-align: middle;
    }
    
    .demoProgress .demoCircle .label {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 3px;
        background: white;
        border: 2px solid #C4C4C4;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #C4C4C4;
        display: inline-block;
    }
    
    .demoProgress .demoCircle.done .label {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 0px;
        background: #FF8D4D;
        border: 2px solid #FF8D4D;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: white;
        display: inline-block;
    }
    
    .demoProgress .demoBar {
        background: #C4C4C4;
        border-radius: 0;
        width: 35px;
        height: 0px;
        margin: 0px 3px;
        border: 1px solid #C4C4C4;
    }
    
    .demoProgress .demoBar.done {
        background: #FF8D4D;
        border-radius: 0;
        width: 35px;
        height: 0px;
        margin: 0px 3px;
        border: 1px solid #FF8D4D;
    }
    
    .demoProgress .demoCircle .description {
        position: inline-block;
        text-align: center;
        width: 50px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        float: left;
        margin-top: 5px;
        margin-left: -15px;
    
        color: #9A9999;
    }
    
    .demoChromeExtButton {
        margin: 0 auto;
        width: fit-content;
        margin-top: 50px;
    }
    
    .demoLinkedinBox {
        margin: 0 auto;
        width: fit-content;
        vertical-align: middle;
        margin-top: 60px;
    }

    .demoIntroContent {
        font-size: 16px;
    }
    
    .demoIntroItem {
        display: flex;
        margin-left: 30px;
        margin-top: 3px;
    }
    
    .demoIntroItem .wander-logo {
        margin-left: 45px;
    }
    
    .demoIntroItem p {
        margin-left: 25px;
        font-size: 15px;
    }

    .demoIntroTitle {
        font-size: 30px;
    }
    
    .demoIntroItem .demoIntroItem3 {
        margin-left: 15px;
    }

    .demoExtInstructionGif {
        height: fit-content;
        width: max-content;
    }
    
    .demoLinkedinFollowing {
        height: 50vh;
        overflow: auto;
    }

    .demoModal {
        height: 90vh;
    }
}

@media only screen and (max-width: 400px) {
    .demoIntroItem {
        display: flex;
        margin-left: 30px;
        margin-top: 3px;
    }
    
    .demoIntroItem .wander-logo {
        margin-left: 45px;
    }
    
    .demoIntroItem p {
        margin-left: 25px;
        font-size: 13px;
    }

    .demoIntroTitle {
        font-size: 26px;
    }
    
    .demoIntroItem .demoIntroItem3 {
        margin-left: 15px;
    }

    .demoIntroContent {
        font-size: 13px;
    }

    .demoLinkedinFollowing {
        height: 47vh;
        overflow: auto;
    }
}