#wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
}

#appContent {
    position: relative;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    transition: all 0.3s;
    height: 100%;
}

#sticky-nav {
    -webkit-box-shadow: 0 0 7px 2px lightgray;
    -khtml-box-shadow: 0 0 7px 2px lightgray;
    -moz-box-shadow: 0 0 7px 2px lightgray;
    box-shadow: 0 0 7px 2px lightgray;
}

.grayOut {
    top: 0;
    z-index: 2;
    background-color: gray !important;
    height: 100%;
    width: 100%;
    opacity: 0.5 !important;
    position: fixed;
    overflow: auto;
}

.noGray {
    display: none;
}

#pageContent {
    padding-top: 67px;
}

.focusBtn:hover {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transition: all 0.3s ease-in-out 0s;
}

.stopFocus:focus, .stopFocus:active {
    outline: none;
    box-shadow: none !important;
}

@media (min-width: 1200px) {
    ul {
      justify-content: center !important;
    }
    #pageContent {
        /*padding-left: 260px;*/
        padding-top: 72px;
    }
    .grayOut {
        display: none;
    }
    .invite {
      margin-left: 25%;
      margin-right: 25%;
    }
    .referral {
      margin-right: 25%;
      margin-left: 25%;
    }
}
