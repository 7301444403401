.form__group {
    position: relative;
    padding: 15px 0 0;
    width: 100%;
  }
  
  .form__field {
    position: relative;
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #9b9b9b;
    outline: 0;
    font-size: 1rem;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }
  .form__field::placeholder {
    color: transparent;
  }
  .form__field:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
  }
  
  .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #9b9b9b;
  }
  
  .form__field:focus {
    padding-bottom: 6px;
    font-weight: 500;
    border-width: 2px;
    border-top:0;
    border-left:0;
    border-right:0;
    border-image: linear-gradient(to right, #ff5c00, #ffc5a5);
    border-image-slice: 1;
  }
  .form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #ff5c00;
    font-weight: 500;
  }
 
  .form__field:required, .form__field:invalid {
    box-shadow: none;
  }

  .loading {
    height: 5px;
  }

  .notLoading {
    height: 0px;
  }

  #search_input::placeholder {
    color: #9b9b9b;
  }