.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
}

#loadingOverlay {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    color: white;
}

#loadingContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 50%;
    width: 20%;
}

.unselected {
    opacity: 0.6;
}

@media only screen and (max-width: 768px) {
    #tagFilterContainer [class*="wrapper"] {
        padding: 0;
    }
}

@media (max-width: 1199.98px) {
    #tagFilterContainer {
        position: sticky;
        top: 67px;
        background-color: white;
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    #tagFilterContainer {
        position: sticky;
        top: 72px;
        background-color: white;
        z-index: 1;
    }
}