.unreadHeader {
    text-shadow: 3px 3px 10px lightgray;
}

.dropdown-toggle:active, .open .dropdown-toggle {
    background-color:transparent !important;
    border-color: transparent !important;
}

.dropdown-toggle[aria-expanded="true"] {
    background-color:transparent !important;
    border-color: transparent !important;
}

.dropdown-toggle:after {
    display: none !important;
}

.unreadToggler {
    border-color: transparent !important;
    display: inline-block !important;
}

.unreadToggler:hover, .unreadToggler:focus, .unreadToggler:active {
    background-color: transparent !important;
    border-color: transparent !important;
}

.unread-option {
    justify-content: center;
    display: flex;
}

.schedule-option {
    justify-content: center;
    display: flex;
}