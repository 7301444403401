.rating-comment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imgWrapper {
  display: block;
  width: 100%;
  height: 0;
  padding: 60% 0 0 0;
  position: relative;
  cursor: pointer;
}

.postImage {
  display: block;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.postImageMod {
  display: block;
  position: absolute;
  object-fit: none;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.follow-btn {
  color: #ff5c00;
  font-size: 0.8rem;
}

.overlay {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  /*width: 100%;
  background: linear-gradient(rgb(192, 192, 192, 0.9), #ffffff00);*/
}

.post-option {
  justify-content: center;
  display: flex;
}

.dropdown-toggle:active, .open .dropdown-toggle {
  background-color:transparent !important;
  border-color: transparent !important;
}

.card-body:hover {
  cursor: cursor;
}

.dropdown-toggle[aria-expanded="true"] {
  background-color:transparent !important;
  border-color: transparent !important;
}

.dropdown-toggle:after {
  display: none !important;
}

.toggler {
  border-color: transparent !important;
  display: inline-block !important;
}

.toggler:hover, .toggler:focus, .toggler:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.delete-icon {
  background: rgb(255, 255, 255, 0.75);
  border-radius: 20px;
}

.post-header {
  overflow: inherit !important;
}

.post-info {
  cursor: cursor;
}

.upvoteIcon {
  cursor: pointer !important;
}

.unread-icon {
  margin-bottom: 1;
  color: #ff5c00; 
  font-size: 28px !important;
  cursor: pointer;
}

.dd-list-item:hover {
  background-color: #ff5c00 !important;
}

