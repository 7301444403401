.unreadCard {
    margin-top: 2%;
    padding: 4px;
}

.unreadDomain {
    padding: 2px;
    font-size: 14px;
    color: black;
}

.unreadTitle {
    font-size: 14px;
    color: gray;
    padding-left: 2px;
}
