.sidebar {
    width: 300px;
    top: 0;
    right: 0;
    height: 100%;
    transition: all 0.3s;
    position: fixed;
    z-index: 1;
    background-color: white;
    border-left: 1px solid lightgray;
    padding-bottom: 15px;
    overflow: auto;
}

.sidebar.close {
    right: -300px;
}