#sidenav {
    width: 260px;
    top: 72px;
    left: 0px;
    height: 100%;
    transition: all 0.2s;
    position: fixed;
    z-index: 1;
    overflow: auto;
    -webkit-box-shadow: 0 0 7px 2px lightgray;
    -khtml-box-shadow: 0 0 7px 2px lightgray;
    -moz-box-shadow: 0 0 7px 2px lightgray;
    box-shadow: 0 0 7px 2px lightgray;
    transform:scaleZ(1);
    clip-path: inset(0.75px -9px 0px 0px);
}

#sidenav1 {
    position: fixed;
    width: 20%;
}

.nav a {
    font-size: 2.0em;
}

#home:hover {
    opacity: 0.6;
}

#explore:hover {
    opacity: 0.6;
}

#profile:hover {
    opacity: 0.6;
}

#follow:hover {
    opacity: 0.6;
}

#admin:hover {
    opacity: 0.6;
}

#home-inactive {
    color: grey;
}

#home-inactive:hover {
    opacity: 0.6;
}

#explore-inactive {
    color: grey;
}

#explore-inactive:hover {
    opacity: 0.6;
}

#profile-inactive {
    color: grey;
}

#profile-inactive:hover {
    opacity: 0.6;
}

#follow-inactive {
    color: grey;
}

#follow-inactive:hover {
    opacity: 0.6;
}

#unread-inactive {
    color: grey;
}

#unread-inactive:hover {
    opacity: 0.6;
}

#admin-inactive {
    color: grey;
}

#admin-inactive:hover {
    opacity: 0.6;
}

#home-inactive-mobile {
    color: grey;
}

#home-inactive-mobile:hover {
    opacity: 0.6;
}

#explore-inactive-mobile {
    color: grey;
}

#explore-inactive-mobile:hover {
    opacity: 0.6;
}

#profile-inactive-mobile {
    color: grey;
}

#profile-inactive-mobile:hover {
    opacity: 0.6;
}

#follow-inactive-mobile {
    color: grey;
}

#follow-inactive-mobile:hover {
    opacity: 0.6;
}

#unread-inactive-mobile {
    color: grey;
}

#unread-inactive-mobile:hover {
    opacity: 0.6;
}

#admin-inactive-mobile {
    color: grey;
}

#admin-inactive-mobile:hover {
    opacity: 0.6;
}

@media (max-width: 575.98px) {
    .menuLink {
        font-size: 30px !important;
        font-weight: 200 !important;
    }
    .sidenav {
        width: 210px;
        top: 0;
        left: 0;
        height: 100%;
        padding-top: 10px;
        transition: all 0.2s;
        position: fixed;
        z-index: 3;
        overflow: auto;
    }

    .sidenav.close {
        left: -210px;
    }

    .homeIcon {
        margin-bottom: 5px !important;
    }

    .exploreIcon {
        margin-bottom: 7px !important;
    }

    .personIcon {
        margin-bottom: 6px !important;
    }

    .peopleIcon {
        margin-bottom: 6px !important;
    }

    .bookmarkIcon {
        margin-bottom: 6px !important;
    }

    .assignmentIcon {
        margin-bottom: 6px !important;
    }
}

@media (min-width: 576px) {
    .menuLink {
        font-size: 33px !important;
        font-weight: 200 !important;
    }
    .sidenav {
        width: 220px;
        top: 0;
        left: 0;
        height: 100%;
        padding-top: 15px;
        transition: all 0.2s;
        position: fixed;
        z-index: 3;
        overflow: auto;
    }

    .sidenav.close {
        left: -220px;
    }

    .homeIcon {
        margin-bottom: 5.5px !important;
    }

    .exploreIcon {
        margin-bottom: 6px !important;
    }

    .personIcon {
        margin-bottom: 5.5px !important;
    }

    .peopleIcon {
        margin-bottom: 5.5px !important;
    }

    .bookmarkIcon {
        margin-bottom: 5.5px !important;
    }

    .assignmentIcon {
        margin-bottom: 5.5px !important;
    }
}
