.playlistIMG {
    width: 100%;
    height: 80px;
    object-fit: cover;
}

.profileIMG {
    object-fit: cover;
    -webkit-box-shadow: 0 0 0px 0px lightgray;
    -khtml-box-shadow: 0 0 0px 0px lightgray;
    -moz-box-shadow: 0 0 0px 0px lightgray;
    box-shadow: 0 0 0px 0px lightgray;
}

.addPlaylist {
    display: flex;
    text-align: right;
}

.editBtn {
    width: 100px;
}

@media (max-width: 575.98px) { 
    .profName {
        font-size: 1.5rem;
        text-shadow: 2px 2px 5px lightgray;
    }
    .secTitles {
        font-size: 1.0625rem;
    }
}

@media (min-width: 576px) { 
    .profName {
        font-size: 1.625rem;
        text-shadow: 2px 2px 5px lightgray;
    }
    .secTitles {
        font-size: 1.125rem;
    }
}

@media (min-width: 1200px) { 
    .profName {
        font-size: 1.875rem;
        text-shadow: 2px 2px 5px lightgray;
    }
    .secTitles {
        font-size: 1.1875rem;
    }
}

