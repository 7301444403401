.section-fullscreen {
    overflow: scroll;
    overflow-wrap: break-word;
    position: relative;
    scroll-snap-type: y mandatory ;
    background-image:url("./images/best_background.png");
    max-height: 100vh;
  }
  
  .container {
    max-width:none;
  }
  
  .item{
    scroll-snap-align: center;
  }

.producthunt-badge {
  position: fixed;
  bottom: 5px;
  left: 5px;
  z-index: 10;
}
  
  .lines {
    top:0;
    left:0;
    position:absolute;
    width:100%;
    object-fit: fill;
  }
  
  .bubble{
    height:100vh;
    width:100vw;
    position:relative;
    justify-content: center;
    align-items: center;
    display: block;
    flex-wrap: nowrap;
    text-align: center;
  }
  .one{
    left:-10vw;
    display:table;
    position:relative;
    top:-15vh;
    margin-left: auto;
    margin-right: auto;
  }
  .one.text.part1{
    position:absolute;
    top:35%;
    left:23%;
    color:#ff5c00;
  }
  .one.text.part2{
    position:absolute;
    top:57%;
    left:27%;
    color:#ff5c00;
  }
  #beta_tag{
    position: absolute;
    top: 44%;
    left: 85%;
    color:#ff5c00;
    font-size: 2.4vmin;
  }
  .createlink{
    position:absolute;
    top: 65%;
    left: 40%;
    height: 4vh;
    width:10%;
  }
  
  .createlinkimg{
    position:absolute;
    top: 0%;
    left: 0%;
    height: 4vh;
  }
  #createLinkText{
    position:absolute;
    top: 30%;
    left:10%;
    color: #fff;
    background-color:#1E223B;
    font-size:1.5vh;
    white-space: nowrap;
  }
  #createLinkText:hover{
    background-color:#353b55;
    transition-duration: 0.4s;
  }
  
  .createlogin{
    position:absolute;
    top: 5vh;
    left:70%;
    z-index: 20;
  }
  #createLoginText{
    position:absolute;
    z-index: 20;
    white-space:nowrap;
    font-size: 1.5vh;
    color:#1E223B;
    background-color:#DBDEE7;
  }
  #createLoginText:hover{
    background-color:#ffffff;
    transition-duration: 0.4s;
  }
  .wanderLandingPageLogo{
    position:absolute;
    left:80%;
    top:2.8vh;
    z-index: 20;
  }
  .createlink_1{
    position:absolute;
    top: 28%;
    left: 35%;
    height: 6vh;
    width:27%;
    z-index: 20;
  }
  .createlinkimg_1{
    position:absolute;
    top: 0%;
    left: 0%;
    height: 4vh;
  }
  #createlinktext_1{
    position:absolute;
    top: 25%;
    left: 5%;
    color: #fff;
    font-size:2.3vh;
    white-space: nowrap;
  }
  
  .two{
    left: 10vw;
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
  }
  .two.text.part1{
    position:absolute;
    top: 25%;
    left: 12%;
    color: #fff;
  }
  .two.text.part2{
    position:absolute;
    top: 45%;
    left: 25%;
    color: #fff;
  }
  
  .three{
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
  }
  .smallOne{
    position:relative;
    display:table;
    margin-left: auto;
    margin-right: auto;
  }
  .landingPagePreview1{
    position:absolute;
    top:0%;
    left:20%;
  }
  .chatLogo{
    position:absolute;
    height:35%;
    top: 7%;
    left:32%;
  }
  .three.text.part1{
    position:absolute;
    top:37%;
    left: 11%;
    color: #fff;
  }
  .three.text.part2{
    position:absolute;
    width:80%;
    top:53%;
    left: 10%;
    color: #fff;
  }
  
  .four{
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
  }
  .smallTwo{
    position:relative;
    display:table;
    margin-left: auto;
    margin-right: auto;
  }
  .peopleLogo{
    position:absolute;
    height:53%;
    top: 0%;
    left:24%;
  }
  .four.text.part1{
    position:absolute;
    width:70%;
    top: 36%;
    left: 15%;
    color: #fff;
  }
  .four.text.part2{
    position:absolute;
    width:60%;
    top: 50%;
    left: 18%;
    color: #fff;
  }
  .landingPagePreview2{
    position:absolute;
    top:-15%;
    left:-10%;
  }
  
  .five{
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    top:0%;
    left:-10%;
  }
  .smallThree{
    position:relative;
    display:table;
    margin-left: auto;
    margin-right: auto;
  }
  .listLogo{
    position:absolute;
    height:38%;
    top: 5%;
    left: 31%;
  }
  .five.text.part1{
    position:absolute;
    width:60%;
    top: 35%;
    left: 18%;
    color: #fff;
  }
  .five.text.part2{
    position:absolute;
    width:80%;
    top: 50%;
    left: 10%;
    color: #fff;
  }
  .landingPagePreview3{
    position:absolute;
    top:25%;
    left:17%;
  }
  
  .six{
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    top:0%;
    left:10%;
  }
  .smallFour{
    position:relative;
    display:table;
    margin-left: auto;
    margin-right: auto;
  }
  .six.text.part1{
    position:absolute;
    width:60%;
    top:38%;
    left:19%;
    color: #fff;
  }
  .six.text.part2{
    position:absolute;
    width:70%;
    top:54%;
    left:15%;
    color: #fff;
  }
  .landingPagePreview4{
    position:absolute;
    top:16%;
    left:17%;
  }
  
  .seven{
    display:table;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    height:90vh;
    top:0%;
  }
  .seven.text.part1{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    top: 14%;
    left:10%;
    color: #1E223B;
  }
  .seven.text.part2{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    top: 42%;
    left:-15%;
    color: #1E223B;
  }
  .seven.text.part3{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    width:80%;
    top: 42%;
    left:35%;
    color: #1E223B;
  }
  .chromeExtensionLogo{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    top: 47%;
    left: 11%;
  }
  .socialMediaButtons{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    top: 47%;
    left: 66%;
  }
  .toTop{
    position:absolute;
    margin-left: auto;
    margin-right: auto;
    top: 0%;
    left: 90%;
  }
  .toTopHover{
    position:absolute;
    color: #ff5c00;
    background-color:transparent;
  }
  .toTopHover:hover{
    filter: brightness(1.8);
    transition-duration: 0.4s;
  }
  
  
  
  .smallBubble{
    height:50vh;
    width:70%;
    top:0;
    left:0;
    position:absolute;
    justify-content: center;
    align-items: center;
  }
  
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  #introduction{
  margin-top: 10%;
  }
  
  .Loginbutton {
  background: #ff5c00 !important;
  z-index: 999;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  font: 400 13px "Poppins", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 10px;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
  }
  
  *.text-black-06 {
  color: rgba(0, 0, 0, 0.6);
  }
  
  .buffer {
  height: 10%;
  }
  
  #signup {
  margin-top: 4%;
  }
  
  #submit{
  background: #ff5c00;
  color: white;
  width: 50%;
  margin-left: 25%;
  }
  
  #submit:hover, .Loginbutton:hover {
  opacity: 0.85;
  }
  
  .formInput {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font: 400 13px "Poppins", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: #e5e6e7;
  padding: 10px;
  color: #131517;
  -webkit-transition: ease-out 0.16s;
  transition: ease-out 0.16s;
  }
  
  .font-weight-extra-light {
  font-weight: 200;
  }
  
  #source{
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 7%;
  }
  
  #contacts{
  background: #ff5c00;
  }
  
  .formInput::placeholder {
  color: #AAAAAA;
  }
  
  .formInput:focus {
  outline: 0;
  box-shadow: none;
  }
  
  .logoWrapper {
  display: block;
  width: 100%;
  height: 0;
  padding: 60% 0 0 0;
  position: relative;
  }
  
  .landingLogo {
  display: block;
  position: absolute;
  object-fit: fill;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  }
  
  /* webViewHorizontal */
  @media not all and (max-width:1200px){
  #createLoginText{
    border-radius:5vmin;
    border-width:0;
    height:5vmin;
    min-width:40px;
    min-height:20px;
    width:12vmin;
    font-size:2.2vmin;
  }
  #wanderLandingPageLogoID{
    height: 12vmin;
  }
  #bubble_1{
    height:110vmin;
  }
  #bubble_1_text_1{
    font-size:20vmin;
  }
  #bubble_1_text_2{
    font-size:4vmin;
  }
  #beta_tag{
    left:82%;
  }
  #createLinkText{
    font-size: 2.6vmin;
    min-width:50px;
    min-height:20px;
    width:30vmin;
    height:8vmin;
    border-radius:10vmin;
    border-width:0;
  }
  #two{
    top:-40vmin;
  }
  #bubble_2{
    height:70vmin;
  }
  #bubble_2_text_1{
    font-size:7vmin;
  }
  #bubble_2_text_2{
    font-size:3.5vmin;
    line-height:1.2;
  }
  #divBubble_3{
    top:50vmin;
  }
  #three{
    left:-12vmin;
  }
  #bubble_3{
    height:100vmin;
  }
  #landingPagePreview_1{
    height:100vmin;
  }
  #smallOne{
    top:-100vmin;
    left:-33%;
  }
  #smallBubble_1{
    height:40vmin;
  }
  #smallBubble_1_text_1{
    font-size:3.3vmin;
  }
  #smallBubble_1_text_2{
    font-size: 1.8vmin;
  }
  #divBubble_4{
    top:80vmin;
  }
  #four{
    left:5vmin;
  }
  #bubble_4{
    height:80vmin;
  }
  #landingPagePreview_2{
    height:88vmin;
    left:-15%;
  }
  #smallTwo{
    top:-65vmin;
    left:30%;
  }
  #smallBubble_2{
    height:52vmin;
  }
  #smallBubble_2_text_1{
    font-size:3.3vmin;
  }
  #smallBubble_2_text_2{
    font-size: 1.9vmin;
  }
  #divBubble_5{
    top:80vmin;
  }
  #bubble_5{
    height:93vmin;
  }
  #landingPagePreview_3{
    height:57vmin;
  }
  #smallThree{
    top:-100vmin;
    left:-30%;
    scroll-snap-align: start;
  }
  #smallBubble_3{
    height:38vmin;
  }
  #smallBubble_3_text_1{
    font-size:3vmin;
  }
  #smallBubble_3_text_2{
    font-size: 2vmin;
  }
  #divBubble_6{
    top:75vmin;
    left:0vmin;
  }
  #bubble_6{
    height:95vmin;
  }
  #landingPagePreview_4{
    height:60vmin;
    left:14vmin;
  }
  #smallFour{
    top:-75vmin;
    left:28%;
  }
  #smallBubble_4{
    height:39vmin;
  }
  #smallBubble_4_text_1{
    font-size:3vmin;
  }
  #smallBubble_4_text_2{
    font-size: 2vmin;
  }
  #upArrow{
    height:7vmin;
  }
  #toTopHover{
    color: #F79A6F;
    background-color:transparent;
    font-size: 2.7vmin;
    width:20vmin;
    height:7vmin;
    border-radius:10vmin;
    border-width:0;
    top: 6vmin;
    left: -8.5vmin;
  }
  #divBubble_7{
    height:80vmin;
    top:90vmin;
    width:100vw;
    overflow:hidden;
  }
  #seven{
    left:-10vmin;
  }
  #bubble_7{
    height:120vmin;
  }
  #wanderLandingPageLogoID2{
    height:4.5vmin;
  }
  #socialMediaButtons{
    height:4.3vmin;
  }
  #bubble_4_text_1{
    font-size:10vmin;
  }
  #bubble_4_text_2{
    font-size: 2.7vmin;
  }
  #bubble_4_text_3{
    font-size: 2.7vmin;
  }
  #createlinktext_1{
    font-size:3.4vmin;
    width:38vmin;
    height:9vmin;
    color:#FFFFFF;
    border-radius:10vmin;
    border-width:0;
  }
  }
  
  
  
  /* webViewVertical */
  @media all and (max-width:1200px){
  #createLoginText{
    border-radius:5vmin;
    border-width:0;
    height:5vmin;
    min-width:40px;
    min-height:20px; 
    width:10vmin;
    font-size:2vmin;
  }
  #wanderLandingPageLogoID{
    height: 10vmin;
  }
  #bubble_1{
    height:90vmin;
  }
  #bubble_1_text_1{
    font-size:17vmin;
  }
  #bubble_1_text_2{
    font-size:3.2vmin;
  }
  #createLinkText{
    font-size:2.3vmin;
    min-width:50px;
    min-height:20px;
    width:25vmin;
    height:6.5vmin;
    border-radius:10vmin;
    border-width:0;
  }
  #two{
    top:-40vmin;
  }
  #bubble_2{
    height:63vmin;
  }
  #bubble_2_text_1{
    font-size:6vmin;
  }
  #bubble_2_text_2{
    font-size:3.2vmin;
    line-height:1.2;
  }
  #divBubble_3{
    top:60vmin;
  }
  #three{
    left:-3.5vmin;
  }
  #bubble_3{
    height:85vmin;
  }
  #landingPagePreview_1{
    height:90vmin;
    top:-8vmin;
    left:17%;
  }
  #smallOne{
    top:-92vmin;
    left:-33%;
  }
  #smallBubble_1{
    height:32vmin;
  }
  #smallBubble_1_text_1{
    font-size:2.7vmin;
  }
  #smallBubble_1_text_2{
    font-size: 1.7vmin;
  }
  #divBubble_4{
    top:80vmin;
  }
  #four{
    left:-1vmin;
  }
  #bubble_4{
    height:72vmin;
  }
  #landingPagePreview_2{
    height:83vmin;
    left:-15%;
  }
  #smallTwo{
    top:-65vmin;
    left:30%;
  }
  #smallBubble_2{
    height:47vmin;
  }
  #smallBubble_2_text_1{
    font-size:3vmin;
  }
  #smallBubble_2_text_2{
    font-size: 1.7vmin;
  }
  #divBubble_5{
    top:80vmin;
    left:5vmin;
  }
  #bubble_5{
    height:83vmin;
    scroll-snap-align: center;
  }
  #landingPagePreview_3{
    height:53vmin;
    left:12vmin;
  }
  #smallThree{
    top:-100vmin;
    left:-37%;
    top:-85vmin;
  }
  #smallBubble_3{
    height:33vmin;
  }
  #smallBubble_3_text_1{
    font-size:3vmin;
  }
  #smallBubble_3_text_2{
    font-size: 2vmin;
  }
  #divBubble_6{
    top:75vmin;
    left:-13vmin;
  }
  #bubble_6{
    height:90vmin;
  }
  #landingPagePreview_4{
    height:55vmin;
    left:14vmin;
  }
  #smallFour{
    top:-60vmin;
    left:38%;
  }
  #smallBubble_4{
    height:35vmin;
  }
  #smallBubble_4_text_1{
    font-size:3vmin;
  }
  #smallBubble_4_text_2{
    font-size: 2vmin;
  }
  #divBubble_7{
    height:80vmin;
    top:90vmin;
    width:100vw;
    overflow:hidden;
  }
  #seven{
    left:0vmin;
    top:10vmin;
  }
  #toTop{
    top:-10vmin;
  }
  #upArrow{
    height:5vmin;
  }
  #toTopHover{
    color: #F79A6F;
    background-color:transparent;
    font-size: 2.3vmin;
    width:20vmin;
    height:7vmin;
    border-radius:10vmin;
    border-width:0;
    top: 4vmin;
    left: -9vmin;
  }
  #bubble_7{
    height:80vmin;
  }
  #wanderLandingPageLogoID2{
    height:4.5vmin;
    top:51vmin;
  }
  #socialMediaButtons{
    height:3.3vmin;
    top:51vmin;
  }
  #bubble_4_text_1{
    font-size:7vmin;
  }
  #bubble_4_text_2{
    font-size: 2.2vmin;
    top:47vmin;
  }
  #bubble_4_text_3{
    font-size: 2.2vmin;
    top:47vmin;
  }
  #createlinktext_1{
    font-size: 2.6vmin;
    width:26vmin;
    height:6vmin;
    color: #FFFFFF;
    border-radius:10vmin;
    border-width:0;
  }
  }
  
  
  
  /* mobileView */
  @media all and (max-width:600px){
  #createLogin{
    top:8vmin;
    left:78vmin;
  }
  #createLoginText{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:3vmin;
    width:15vmin;
    height:7vmin;
    border-radius:10vmin;
    border-width:0;
  }
  #spanWanderLandingPageLogo{
    top:5vmin;
    left:5vmin;
  }
  #wanderLandingPageLogoID{
    height: 13vmin;
  }
  #divBubble_1{
    top:40vmin;
  }
  #bubble_1{
    height:100vmin;
  }
  #bubble_1_text_1{
    font-size:22vmin;
    top:30%;
    left:18%;
  }
  #beta_tag{
    top: 40%;
    left: 86%;
    font-size: 2.5vmin;
  }
  #bubble_1_text_2{
    font-size:4.5vmin;
    top:56%;
    left:21%;
  }
  #createLinkText{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 3vmin;
    width:33vmin;
    height:7vmin;
    top:0%;
    left:-30%;
    border-radius:4vmin;
    border-width:0;
  }
  #two{
    top:-50vmin;
  }
  #bubble_2{
    height:75vmin;
  }
  #bubble_2_text_1{
    font-size:6.5vmin;
    top:26%;
    left:12%;
  }
  #bubble_2_text_2{
    font-size:4vmin;
    top:43%;
    left:20%;
  }
  #divBubble_3{
    top:80vmin;
  }
  #three{
    left:-6vmin;
  }
  #bubble_3{
    left:0vmin;
    height:90vmin;
  }
  #landingPagePreview_1{
    height:95vmin;
    left: 18vmin;
    top:-9vmin;
  }
  #smallOne{
    top:-100vmin;
    right:32vmin;
  }
  #smallBubble_1{
    height:40vmin;
    top:-45vmin;
  }
  #smallBubble_1_text_1{
    font-size:3.5vmin;
  }
  #smallBubble_1_text_2{
    font-size: 2.7vmin;
  }
  #divBubble_4{
    top:100vmin;
  }
  #four{
    left:-10vmin;
  }
  #bubble_4{
    height:85vmin;
  }
  #landingPagePreview_2{
    height:85vmin;
    top:0vmin;
    left:-14vmin;
  }
  #smallTwo{
    top:-75vmin;
    left:27vmin;
  }
  #smallBubble_2{
    height:60vmin;
  }
  #smallBubble_2_text_1{
    font-size:3.8vmin;
  }
  #smallBubble_2_text_2{
    font-size: 3vmin;
    left:12vmin;
  }
  #divBubble_5{
    top:120vmin;
  }
  #five{
    left:-14vmin;
  }
  #bubble_5{
    height:100vmin;
  }
  #landingPagePreview_3{
    height:60vmin;
    left:9vmin;
    top:15vmin;
  }
  #smallThree{
    top:-35vmin;
    left:-3vmin;
  }
  #smallBubble_3{
    height:40vmin;
  }
  #listLogo{
    top:2vmin;
    left:14.7vmin;
  }
  #smallBubble_3_text_1{
    font-size:4vmin;
    top:13.5vmin;
    left:8.5vmin;
  }
  #smallBubble_3_text_2{
    font-size:3vmin;
    left:5vmin;
    top:19vmin;
  }
  #divBubble_6{
    top:110vmin;
  }
  #six{
    left:8vmin
  }
  #bubble_6{
    height:90vmin;
  }
  #landingPagePreview_4{
    height:60vmin;
    left:15vmin;
    top:12vmin;
  }
  #smallFour{
    top:-65vmin;
    left:40vmin;
  }
  #smallBubble_4{
    height:40vmin;
  }
  #listLogo_1{
    top:0vmin;
  }
  #smallBubble_4_text_1{
    font-size:4vmin;
    top:12vmin;
    left:8vmin;
  }
  #smallBubble_4_text_2{
    font-size:3vmin;
    top:18vmin;
    left:6vmin;
  }
  #divBubble_7{
    top:80vmin;
    width:100vmin;
  }
  #seven{
    left:-2vmin;
  }
  #toTop{
    top:-10vmin;
  }
  #upArrow{
    height:5vmin;
  }
  #toTopHover{
    color: #F79A6F;
    background-color:transparent;
    font-size: 2.3vmin;
    width:20vmin;
    height:7vmin;
    border-radius:10vmin;
    border-width:0;
    top: 4vmin;
    left: -9vmin;
  }
  #bubble_7{
    width:105vmin;
  }
  #wanderLandingPageLogoID2{
    height:5vmin;
    top:55vmin;
    left:14vmin;
  }
  #socialMediaButtons{
    height:4vmin;
    top:55vmin;
    left:64vmin;
  }
  #bubble_4_text_1{
    font-size:8vmin;
    top:20vmin;
  }
  #bubble_4_text_2{
    font-size:2.5vmin;
    top:50vmin;
    left:-15vmin;
  }
  #bubble_4_text_3{
    font-size:2.5vmin;
    top:50vmin;
    left:35vmin;
  }
  #createLink_1{
    top:30vmin;
    left:35vmin;
  }
  #createlinktext_1{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 3vmin;
    width:33vmin;
    height:7vmin;
    color: #FFFFFF;
    left:-5%,;
    border-radius:4vmin;
    border-width:0;
  }
  }
  
  
  @font-face {
  font-family: "poppins";
  src: url("/fonts/OpenSans-Regular-webfont.woff2") format("woff2"),
        url("/fonts/OpenSans-Regular-webfont.woff") format("woff");
  }
  @font-face {
  font-family: "futura";
  src: url("/fonts/OpenSans-Regular-webfont.woff2") format("woff2"),
        url("/fonts/OpenSans-Regular-webfont.woff") format("woff");
  }