.commentUserText {
  font-size: 14px;
  font-weight: bold;
}

.commentText {
  font-size: 14px;
}

.commentTimeText {
  font-size: 14px;
  color: gray;
}

.delete-outer {
  height: 20px;
}

.comment-option {
  justify-content: center;
  display: flex;
}
