.selectedTagCreate {
    width: 100%;
    border: 2px solid white;
    box-shadow: 0 0 0 2px black;
    opacity: 0.5;
}

.unselectedTagCreate {
    border: 1px solid white;
    width: 100%;
}

.selectedTag {
    border: 1px solid black;
    width: 100%;
}

.unselectedTag {
    border: 1px solid white;
    width: 100%;
}

#tagButton {
    cursor: pointer;
    max-width: 100%;
}

#tagButton:hover {
    opacity: 0.8;
}

.menu-item-wrapper {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}