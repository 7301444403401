.commentsContainer {
    max-height: 200px;
}

.commentDivider {
    height: 1px;
    width: 100%;
    background-color: whitesmoke;
}

.mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}

.comment-editor {
    border-radius: 20px !important;
}

/* Code below for ckeditor toolbar + border radius + sizing */
.advanced .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, 
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100px;
    line-height: 20px;
    overflow: scroll;
}

.advanced .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100px;
    line-height: 20px;
    overflow: scroll;
}

.advanced .ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
    padding-left: 0.5em;
}

.advanced .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

:root {
    --ck-z-default: 100 !important;
}

.ck.ck-reset.ck-list.ck-mentions > .ck.ck-list__item > .ck.ck-button.ck-on.ck-button_with-text {
    background-color: #f26a27;
}