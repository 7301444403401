.nativePost {
  height: 100%;
  background: #E6E6E6;
  width: 100%;
  position: relative;
}

.actionModal {
  padding: 0px;
}

.actionButton {
  position: fixed;
  right: 0px;
  top: 200px;
}

.backButton:hover {
  cursor: pointer
}

#post-sidenav {
  display: none !important;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.container-fluid {
  height: 100%;
}

#post-wrapper {
  height: 100%;
}

#post-pageContent {
  display: block;
  position: relative;
  height: calc(100% - 67px);
  top: 67px;
  overflow: hidden;
}

.htmlContent img {
  max-width: 100%;
  border-radius: 5px;
}

.nativeWrapper {
  display: block;
  width: 100%;
  height: 0;
  padding: 60% 0 0 0;
  position: relative;
}

.nativeImage {
  display: block;
  position: absolute;
  object-fit: fill;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 5px;
}

@media (min-width: 1200px) {
  #post-pageContent {
    top: 72px;
    height: calc(100% - 72px);
  }
}
